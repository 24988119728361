@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.editorless-container {
  /* display: flex;
  flex-direction: column;
  height: 84vh; */
}

.editorless-container .content {
  flex: 1;
}

body {
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 1.6;
  width: 100%;
}

.split {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

blockquote {
  color: #ffffff;
  padding-left: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 1em 0;
  border-left: 5px solid #cccccc;
  font-family: monospace;
  font-size: 1.1em;
}

.custom-link {
  text-decoration: none;
  color: white;
}

.custom-link:hover {
  text-decoration: underline;
  color: rgb(18, 168, 206);
}

st {
  font-family: monospace;
  color: rgb(216, 180, 254);
  font-size: 1.1em;
}

.course-container {
  /* max-width: 200vw; */
  margin: auto;
  padding: 0 20px;
}

.leaderboard {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
}

header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  color: #333;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 15px;
  text-align: center;
}

.card.reverse {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}

.card h2 {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

h2 {
  font-weight: bold;
}

input {
  flex-grow: 2;
  border: none;
  font-size: 16px;
}

input:focus {
  outline: none;
}

.num-display {
  position: absolute;
  top: -10px;
  left: -10px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.close,
.edit {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  background: none;
  border: none;
}

.edit {
  right: 40px;
}

.btn {
  border: 0;
  border-radius: 8px;
  color: #fff;
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.btn-circle {
  border-radius: 300px;
}

/* code {
  background-color: rgb(30, 30, 30);
  
} */

#lesson-panel p a {
  color: rgb(216, 180, 254);
}
#lesson-panel p a:hover {
  text-decoration: underline;
}

p code {
  background-color: #3c3a3a;
  color: white;
  padding-left: 0.75%;
  padding-right: 0.75%;
}

pre div {
  background: #232222 !important;
}

.editor {
  background-color: #1e1e1e;
}

.splitHor {
  display: flex;
  flex-direction: row;
  min-height: 78vh !important; /* 100vh - LessonFooter"
    s 5vh - navbar vh * /;
}

.navbar {
  height: 8vh !important;
  min-height: 8vh !important;
}

.competition-nav {
  height: 8vh !important;
  min-height: 8vh !important;
  box-shadow: 0px 1px 2px rgb(216, 180, 254), 0px 1px 2px rgb(216, 180, 254),
    0px 2px 4px rgb(216, 180, 254), 0px 8px 16px rgb(216, 180, 254);
  /* background-color: cream; */
}

.main-nav {
  height: 8vh !important;
  min-height: 8vh !important;
  box-shadow: 0px 1px 2px rgb(216, 180, 254), 0px 1px 2px rgb(216, 180, 254),
    0px 2px 4px rgb(216, 180, 254), 0px 8px 16px rgb(216, 180, 254);
  /* background-color: cream; */
}

.auth-page-center {
  text-align: center;
  vertical-align: middle;
}

.home-category:hover {
  border: 0.25rem solid rgb(135, 90, 184);
}
.home-category {
  border: 0.25rem solid transparent;
  border-radius: 25px;
}

.why-are-you-looking-for-this {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
}
.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: row-resize;
}

.iPadShowKeyboard {
  display: none;
}

.lessonBtn {
  width: 16rem;
}

.lesson-btn-small {
  width: 7rem;
  font-size: 0.6rem;
}

.btn:hover {
  transform: scale(0.98);
  opacity: 0.9;
}

.btn:disabled {
  background-color: #cccccc;
  color: #333;
  cursor: auto;
}

.btn:disabled:hover {
  transform: scale(1);
  opacity: 1;
}

p a {
  color: #d09e99;
  text-decoration: none;
}

li a {
  color: #d09e99;
  text-decoration: none;
}

#lesson-panel table,
#lesson-panel th,
#lesson-panel td {
  border: 2px solid rgb(216, 180, 254);
  background-color: rgb(20, 20, 20);
  text-align: center;
  padding: 10px;
}

#lesson-panel table {
  margin: auto;
}

#lesson-panel p img {
  max-width: 100%; /* Ensures the image doesn't exceed its container's width */
  height: auto; /* Allows the image to maintain its aspect ratio */
  width: 50%;
  margin-left: 25%;
}

#lesson-panel p img:hover {
  transform: scale(1.15);
}

#lesson-panel li {
  list-style-type: disc;
  margin-left: 2em;
}

#challenge-panel li {
  list-style-type: disc;
  margin-left: 2em;
}

/* Contest */
.neon {
  /* font-family: neon; */
  color: #fed128;
}

.register-button {
  background-color: rgb(216, 180, 254);
}

.register-button:hover {
  background-color: rgb(255, 200, 0);
  color: black;
}

.competitionCard:hover {
  box-shadow: 0px 20px 50px rgba(194, 216, 55, 0.2);
}

.competitions-container {
  height: calc(77.25vh);
}

.competitions-leaderboard {
  height: calc(37.8vh);
  width: calc(25vw);
}

.dov:hover {
  color: rgb(216, 180, 254);
}
